<template>
  <div>
    <v-card>
      <v-card-title class="display-2">Tile map</v-card-title>
      <TileMapIntro class="mb-4" />

      <TileMap ref="tileMap" :country="this.$route.params.country" @selected="tileSelected"/>
    
    </v-card>
  </div>
</template>

<script>
// Todo: add swipe/scroll notification from https://codepen.io/surisdziugas/pen/LzXPwz

import { mapGetters } from "vuex";
import TileMap from "@/components/TileMap";
import TileMapIntro from "@/components/TileMapIntro";

export default {
  name: "Map",
  components: {
    TileMap,
    TileMapIntro,
  },
  props: {
  },
  data() {
    return {
      profile: null,
      activityType: 'RUNNING',
      tile: null,
    };
  },
  async mounted() {
  },
  methods: {
    async tileSelected(tile) {
      this.tile = tile;
      this.$refs.tileMap.highlightTile(tile);
    },

  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },

};
</script>
<style lang="scss">
</style>

